.basicOne
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"] {
  padding: 0px 12px !important;
  color: #2f3542 !important;
  font-weight: 400;
  font-size: 14px;
  background: #fff;
  border-radius: 6px;
}
.basicOne div[class="ant-segmented-group"] label {
  display: flex !important;
  align-items: center !important;
}
.basicOne {
  border-radius: 8px;
  /* border: 1px solid #d9d9d9; */
  /* background: #f9fafb; */
  /* padding: 2px; */
}

.basicOne div[class="ant-segmented-group"] {
  display: flex;
  gap: 8px;
  background: #fff;
  color: #2f3542 !important;
  border-radius: 6px;
  padding: 6px;
}

.basicOne
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"] {
  border-radius: 6px;
  background: #7369f4;
  /* border: 1px solid #d9d9d9; */
  font-weight: 400;
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06),
    0px 1px 3px 0px rgba(16, 24, 40, 0.1);
}
.basicOne
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"]:hover {
  /* background: #fff; */
  color: #7369f4 !important;
}
/* .basicOne
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"]:hover {
  color: #fff !important;
} */

.outLined div[class="ant-segmented-group"] label {
  display: flex !important;
  align-items: center !important;
}
.outLined div[class="ant-segmented-group"] {
  display: flex;
  gap: 8px;
}
.outLined
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"] {
  border-radius: 6px;
  background: #f9f5ff;
}
.outLined {
  border-radius: 8px;
  background: transparent;
}
.outLined
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"] {
  padding: 8px 12px !important;
  color: #667085;
  font-weight: 500;
}

.outLined
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"]
  div[class="ant-segmented-item-label"] {
  color: #6941c6;
}

.outLined
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]:hover {
  background-color: #ffffff !important;
}

.bottomBorderWithBG div[class="ant-segmented-group"] label {
  display: flex !important;
  align-items: center !important;
}
.bottomBorderWithBG div[class="ant-segmented-group"] {
  display: flex;
  gap: 8px;
}
.bottomBorderWithBG
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"]
  div[class="ant-segmented-item-label"] {
  color: #6941c6;
}
.bottomBorderWithBG
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"] {
  padding: 8px 12px !important;
  color: #667085;
  font-weight: 500;
}

.bottomBorderWithBG {
  background: transparent;
  border-bottom: 1px solid #eaecf0;
  padding: 0px;
}

.bottomBorderWithBG
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"] {
  border-bottom: 2px solid #6941c6;
  border-radius: 0px;
  background-color: #f9f5ff;
}
/* ********************************************************************** */
.bottomBorder div[class="ant-segmented-group"] label {
  display: flex !important;
  align-items: center !important;
}
.bottomBorder div[class="ant-segmented-group"] {
  display: flex;
  gap: 8px;
}
.bottomBorder
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"]
  div[class="ant-segmented-item-label"] {
  color: #6941c6;
}
.bottomBorder
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item"]
  div[class="ant-segmented-item-label"] {
  padding: 8px 12px;

  color: #667085;
  font-weight: 500;
  box-shadow: none !important;
}

.bottomBorder {
  background: transparent;
  border-bottom: 1px solid #eaecf0;
  padding: 0px;
  border-radius: 0px;
}

.bottomBorder
  div[class="ant-segmented-group"]
  label[class="ant-segmented-item ant-segmented-item-selected"] {
  border-bottom: 2px solid #6941c6;
  background: transparent;
  border-radius: 0px;
  box-shadow: none;
}

@media screen and (max-width: 1600px) {
  .basicOne
    div[class="ant-segmented-group"]
    label[class="ant-segmented-item"]
    div[class="ant-segmented-item-label"] {
    padding: 4px 12px !important;
  }
}
