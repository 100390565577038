.course-card .ant-card-body {
  padding: 0px !important;
}

.course-card img {
  max-width: 240px;
}

.course-create-cards {
  cursor: pointer;
}

.course-create-cards .ant-card-body {
  padding: 8px;
}

.course-create-cards img {
  max-width: 238px;
}

.course-container {
  position: relative;
}

.course-container .ant-checkbox-wrapper {
  position: absolute !important;
  top: 0;
  right: 0;
  padding-right: 5px;
}

.selected-tyuts img {
  max-width: 120px;
}

.tyutCardLessDescriptive {
  margin-bottom: 10px;
  /* position: relative; */
  /* border: none !important; */
}

.tyutCardLessDescriptive:hover {
  background: #f8f9ff;
}

.activetyutCardLessDescriptive {
  background: #f8f9ff;
  border-color: #97affe !important;
  border: 1px solid #97affe !important;
}

.floatingActionButtons {
  position: absolute;
  bottom: -40px;
  padding: 16px 24px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background: #fff;
  border: 1px solid #ebecf0;
  box-shadow: 0px 1px 0px 0px #f0f0f0 inset;
  z-index: 2;
}
