.fileListItem {
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  padding: 3px 4px 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 6px;
  cursor: pointer;
}

.assetsUpload div {
  width: 100% !important;
}

.assetImageParent:hover span {
  opacity: 1 !important;
}
.assetImageParent {
  text-align: center;
}
.assetImageParent {
  height: 300px;
  width: 100%;
}

.assetImageParent div {
  /* opacity: 0.5; */
  /* height: 300px;
  width: 100%; */
  border-radius: 12px;
  background: rgba(39, 39, 39, 0.5);
}
.assetDeleteIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  cursor: pointer;
  font-size: 200;
  color: #fff;
  opacity: 0;
  font-size: 20px;
}

.assetImageParent:hover .assetDeleteIcon {
  opacity: 1;
}
.assetImageParent:hover .assetImageMask {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ant-color-text-light-solid);
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  height: 300px;
  width: 100%;
  border-radius: 12px;
  /* cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  transition: opacity var(--ant-motion-duration-slow); */
}
.recharts-tooltip-label {
  display: none;
}

.assetName:hover {
  color: #1677ff;
}
.treeSelect .ant-select-selector {
  border: 0 !important;
  box-shadow: none !important; 
  padding-right: 0 !important;
}
.treeSelect .ant-select-arrow {
  display: none !important;
}