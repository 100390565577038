.signupContainer {
  min-height: 100vh;
}

.contentContainer {
  background-image: url("../../../assets/images/cmms-logos/loginBG.svg");
  background-repeat: no-repeat;
  background-size: auto 30vw;
  background-position: -32vw 10vh;
  height: 100vh;
  /* width: 100px; */
}
div.signupSteps[class="ant-steps"] div[class="ant-steps-item"] {
  color: #fff !important;
}
.signupSteps {
  min-height: 300px !important;
}

.signupSteps .ant-steps-item {
  margin-block: 12px;
}
.signupSteps .ant-steps-item-tail {
  margin-block: 24px;
  margin-inline: 12px;
}
.signupSteps .ant-steps-item-title {
  font-weight: 600;
}
.signupSteps .ant-steps-item-description {
  font-size: 14px !important;
}

.webHidden {
  display: none;
}
.mobileHidden {
  display: block;
}

@media screen and (max-width: 1600px) {
  .dominSuffix {
    height: 38px;
  }
}
@media screen and (max-width: 992px) {
  .webHidden {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .mobileHidden {
    display: none;
  }
}
