.body {
  width: 100vw;
  min-height: 100vh;
  background-color: #f0f1f6;
}
.sider {
  background-color: #fff !important;
  /* padding: 1.25vw;
  padding-top: 12px; */
  padding: 12px 0px 24px 0px;
  border-right: 1px solid #eaecf0;
  height: 100vh;
  position: fixed !important;
  top: 0;
}

.siderWidth {
  min-width: 220px !important;
  max-width: 220px !important;
}

.siderNoCollapse {
  width: 220px !important;
  min-width: 220px !important;
  /* padding-inline: 12px !important; */
  border-right: 1px solid #d0d5dd !important;
}
.siderCollapse {
  width: 100px !important;
  min-width: 100px !important;
}
.siderNoCollapse div[class="ant-layout-sider-trigger"] {
  width: 10vw !important;
  background-color: #7369f4;
}
.siderCollapse div[class="ant-layout-sider-trigger"] {
  width: 40px !important;
  background-color: #7369f4;
}
/* .siderCollapse div[class="ant-layout-sider-trigger"] {
  width: 40px !important;
  background-color: #fff;
} */

.header {
  background-color: #fff;
  line-height: 64px;
  padding: 12px 2vw 12px 24px !important;
  height: 64px;
  position: fixed !important;
  top: 0;
  /* width: calc(100vw - 220px);
  margin-left: 220px; */
  z-index: 100;
  border-bottom: 1px solid #d0d5dd;
}

.headerWithSideAndCollapse {
  width: calc(100vw - 100px) !important;
  left: calc(100px);
  padding: 0px;
}
.headerWithSideAndNoCollapse {
  width: calc(100vw - 220px) !important;
  left: calc(220px);
  padding: 0px;
}
.content {
  padding: 16px 2vw 16px 16px;
  background-color: #f0f1f6;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-left: 220px;
  margin-top: 64px;
}
.contentWithFooter {
  padding: 16px 16px 114px 16px;
  background-color: #f0f1f6;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-left: 220px;
  margin-top: 64px;
}

.contentWithFooterNoCollapse {
  padding: 16px 16px 114px 16px;
  background-color: #f0f1f6;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-left: 220px;
  margin-top: 64px;
}

.contentWIthFooterCollapse {
  margin-left: 100px;
  overflow-y: auto;
  margin-top: 64px;
}

.contentWithNoFooterNoCollapse {
  padding: 16px 16px 16px 16px;
  background-color: #f0f1f6;
  min-height: calc(100vh - 64px);
  overflow-y: auto;
  margin-left: 220px;
  margin-top: 64px;
}

.contentWIthNoFooterCollapse {
  margin-left: 100px;
  overflow-y: auto;
  padding: 16px 16px 16px 16px;
  margin-top: 64px;
}

.sideMenu.ant-menu-light {
  background-color: #fff !important;
}



/* .sideMenu .ant-menu-item-selected {
  font-weight: 600 !important;
} */

.sideMenu {
  /* max-width: 12vw;
  width: 12vw; */
  border-inline-end: 0px !important;
}
.sideMenu .ant-menu-submenu .ant-menu-sub {
  background: transparent !important;
  padding-left: 20px;
}
.sideMenuCollapsed {
  /* max-width: 70px;
  width: 70px; */
  border-inline-end: 0px !important;
  padding-left: 16px;
  padding-right: 16px;
}

.sideMenuCollapsed.ant-menu-light {
  background-color: #fff !important;
}

.sideMenuCollapsed .ant-menu-item {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
}

.sideMenuCollapsed .ant-menu-item-selected {
  font-weight: 600 !important;
}

.footer {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  /* left: 220px; */
  /* width: calc(100vw - 220px); */
  z-index: 100;
  border-top: 1px solid #d0d5dd;
}

.footerStyleWithCollapsedSide {
  width: calc(100vw - 100px);
  left: calc(100px);
  padding-inline: 32px 3vw;
}
.footerStyleWithNOCollapsedSide {
  width: calc(100vw - 220px);
  left: calc(220px);
  padding-inline: 32px 3vw;
}

.profileMenu {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}
.settingsdropDown {
  width: 300px;
}
.sideMenuLogoWithNoCollapse {
  /* width: 100px !important;
  height: auto !important; */
  margin-inline: 4px;
}
.sideMenuLogoWithCollapse {
  width: 35px !important;
  height: auto !important;
  margin-inline: 4px;
}

.buttonFrame {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.businessBtn {
  padding: 12px;
  gap: 6px;
  border: 1px solid #eaecf0;
  cursor: pointer;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.businessBtn:hover {
  background-color: #f9f5ff;
  border-radius: 8px;
}
.popOverBody {
  width: 18vw;
  padding: 24px;
}

/* .forgot-password .ant-form-item-explain-error {
  color: red;
  font-size: 12px; 
  margin-top: 0px; 
} */

@media screen and (min-width: 768px) {
  /* .sider {
    width: 250px !important;
  } */
  .content {
    padding: 16px 2vw 16px 16px;
    background-color: #f0f1f6;
    min-height: calc(100vh - 64px);
    overflow-y: auto;
    /* margin-left: 250px !important; */
    margin-top: 64px;
  }
  .contentWithFooter {
    padding: 16px 16px 114px 16px;
    background-color: #f0f1f6;
    min-height: calc(100vh - 64px);
    overflow-y: auto;
    /* margin-left: 250px !important; */
    margin-top: 64px;
  }
  .siderWidth {
    /* min-width: 250px !important;
    max-width: 250px !important; */
  }

  .sideMenu {
    /* max-width: 250px !important;
    width: 250px; */
    border-inline-end: 0px !important;
  }
  .sideMenuLogo {
    /* width: 100px !important;
    height: auto !important; */
    height: 30px;
    width: auto;
  }
  .smHidden {
    display: none;
  }
}
