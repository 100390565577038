.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 84px);
  padding: 10px 10% 20px;

}

.chart-card-btn {
  width: 100%;
  min-height: 50px;
  font-weight: 500;
  border: 1px solid;
  font-size: 16px;
  transition: all 0.3s ease-in-out;
  flex-wrap: wrap;
  text-align: center;
  white-space: nowrap; 
}

.chart-card-btn:hover {
  transform: scale(1.03); /* Slightly reduced scale */
  box-shadow: 2px 4px 6px rgba(183, 183, 183, 0.25);
}
.chat-input{
    padding: 0px;
    display: flex;
}

.chat-input .ant-image-img{
    width: auto;
}

.message-header{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 8px;
}
.message-header-avatar{
  background-color: #7369f4;
  padding: 4px;
  border-radius: 8px;
}
.message-header-name{
  font-size: 14px;
  font-weight: 600;
}
.message-header-time{
  font-size: 12px;
  color: #8c8c8c;
}
.message-body{
  width: fit-content;
  background-color: #ffff;
}
.insights-cards{
    min-height: 100px;
    /* box-shadow: 2px 3px 8px #cacaca; */
}
.insights-cards .ant-table-thead p {
 font-weight: 400;
 padding: 0;
 margin: 0;
}

/* Responsive Adjustments */
@media screen and (max-width: 1300px) {
  .chart-card-btn {
    font-size: 12px;
    min-height: 45px;
  }
}

@media screen and (max-width: 768px) {
  .chart-card-btn {
    font-size: 13px;
    min-height: 40px;
  }
}
