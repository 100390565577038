.container {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 0px !important;
}

.statusActive {
  background-color: #d1e0ff !important;
  color: #00359e !important;
  border: 1px solid #00359e !important;
}
.status {
  border-radius: 4px !important;
  border: 1px solid #d0d5dd !important;
  background: #fff !important;
  color: #1d2939 !important;
}
.customDatePickerWidth
  div[class="ant-picker-panel-container ant-picker-date-panel-container"] {
  width: 23vw !important;
  gap: 12px !important;
}
.customDatePickerWidth
  div[class="ant-picker-panel-container ant-picker-date-panel-container"]
  div[class="ant-picker-panel-layout"] {
  width: 23vw !important;
  gap: 12px;
}
.customDatePickerWidth
  div[class="ant-picker-panel-container ant-picker-date-panel-container"]
  div[class="ant-picker-panel-layout"]
  div
  div
  div[class="ant-picker-date-panel"] {
  width: 23vw !important;
}

.flex {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (max-width: 1200px) {
  .customDatePickerWidth
    div[class="ant-picker-panel-container ant-picker-date-panel-container"] {
    width: 40vw !important;
    gap: 12px !important;
  }
  .customDatePickerWidth
    div[class="ant-picker-panel-container ant-picker-date-panel-container"]
    div[class="ant-picker-panel-layout"] {
    width: 40vw !important;
    gap: 12px;
  }
  .customDatePickerWidth
    div[class="ant-picker-panel-container ant-picker-date-panel-container"]
    div[class="ant-picker-panel-layout"]
    div
    div
    div[class="ant-picker-date-panel"] {
    width: 40vw !important;
  }
}
