.locationText div div[class="locationActions"] {
  opacity: 0;
  height: 24px;
}

.locationText:hover div div[class="locationActions"] {
  opacity: 1;
  z-index: 10;
  display: flex;
  align-items: center;
}
