.rbc-event-label {
  color: #fff;
  font-weight: 400;
  margin-bottom: 6px;
}

.rbc-events-container {
  width: 100% !important;
}
.rbc-event {
  /* border: none !important; */
  border-color: #d0d5dd !important;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding-block: 2px;
}
.rbc-allday-cell {
  display: none;
}
.rbc-header {
  background: #2f3542;
  color: white;
  padding: 10px;
  font-size: 12px;
}
/* .rbc-today {
  background: #fff !important;
} */
.rbc-timeslot-group {
  min-height: 60px;
  background-color: #ffffff;
}
.rbc-today .rbc-timeslot-group {
  background-color: #fafcd7 !important;
  color: #2f3542;
}
.rbc-header button {
  background-color: #2f3542 !important;
  color: #ffffff;
  margin-block: 6px;
}
.rbc-today button {
  background-color: transparent !important;
  color: #2f3542;
  margin-block: 6px;
}
.rbc-toolbar button {
  background-color: #fff;
}

.rbc-time-content > .rbc-time-gutter {
  background-color: #fff;
  font-size: 13.33px !important;
}

.rbc-time-header-gutter {
  background-color: #fff !important;
}
.rbc-toolbar {
  font-size: 14px;
}
.selectLikeText div[class="ant-select-selector"] {
  border: 0px !important;
  background: none !important;
  padding: 0px !important;
}
.selectLikeText span[class="ant-select-arrow"] {
  display: none;
}
.dateLikeText {
  width: 100%;
  border: 0px;
  background: none !important;
  padding: 0px;
}
.dateLikeText div span {
  display: none;
}

.rbc-agenda-table tbody tr {
  background-color: #fff !important;
  color: #2f3542 !important;
  font-weight: 600;
}
.rbc-agenda-time-cell,
.rbc-agenda-date-cell {
  font-weight: 500;
}
.rbc-agenda-event-cell div div article {
  color: #2f3542 !important;
  font-weight: 500;
}
.rbc-event-content div div article {
  color: #420d66 !important;
  line-height: 18px !important;
}
.rbc-event-label {
  color: #1b1a1b !important;
}
.rbc-toolbar button.rbc-active {
  background-color: #7369f4 !important;
  box-shadow: none !important;
  color: #fff !important;
  border-color: #7369f4 !important;
}
/* .rbc-toolbar button {
  background-color: #7369F4 !important;
  box-shadow: none !important;
  color: #fff !important;
  border: 0px;
} */

.rbc-agenda-empty {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50vh;
  font-size: 20px;
}

.rbc-event-content .calenderEvent div .eventName {
  display: none !important;
}
.rbc-btn-group button {
  background-color: #fff !important;
}

.rbc-toolbar button:active,
.rbc-toolbar button:focus {
  background-color: #7369f4 !important;
  color: #fff !important;
}

.assetPmCard {
  padding: 6px;
  border-radius: 6px;
  /* background-color: rgba(169, 172, 237, 0.9); */
}

.schedulerTabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  margin-bottom: 6px !important;
}
