.loginContainer {
  padding-top: 45px;
  width: 100%;
  min-height: calc(100vh - 45px);
  /* background-image: url("/images/auth/ellipse.svg"); */
  background-position: 94vw -20vh;
  background-size: auto 20vw;
  background-repeat: no-repeat;
}

.orgButton:hover {
  background: #f9f5ff;
}
.orgErrorButton {
  background: #f9f5ff;
  /* border-bottom: 0px !important; */
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.orgButtonError {
  background: #fef3f2;
  padding: 8px 16px;
  border: 1px solid #d0d5dd;
  border-top: 0px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.orgButtonErrorText {
  text-align: left;
  font-size: 12px;
  font-weight: 300;
  color: #475467;
  line-height: 18px;
}

.orgModal div[class="ant-modal-content"] {
  padding: 0px;
}

.scrollDiv::-webkit-scrollbar {
  width: 10px;
}
.scrollDiv::-webkit-scrollbar-thumb {
  background-color: #eaecf0;
  border-radius: 6px;
}
.scrollDiv::-webkit-scrollbar-track {
  background-color: transparent;
}
.webHidden {
  display: none;
}
.mobileHidden {
  display: block;
}
.orgTabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {

  margin-bottom: 6px !important;
}
@media screen and (max-width: 992px) {
  .webHidden {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .mobileHidden {
    display: none;
  }
}
.custom-table .ant-table-tbody > tr {
  background-color: #FAFAFA; /* Set your desired background color */
}



.custom-table .ant-table-tbody > tr:hover {
  background-color: #d9d9d9; /* Add hover effect to rows */
}