div.basicProgress span[class="ant-progress-text"] {
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
div.basicProgress
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"] {
  border-radius: 4px;
}

div.basicProgress div[class="ant-progress-outer"] {
  border-radius: 4px;
}
div.basicProgress
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"]
  div[class="ant-progress-bg"] {
  border-radius: 4px;
}

div.progressWithTextDown span[class="ant-progress-text"] {
  display: block;
  width: 100%;
  text-align: right;
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
div.progressWithTextDown
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"] {
  border-radius: 4px;
}

div.progressWithTextDown div[class="ant-progress-outer"] {
  padding-inline-end: 0px !important;
  border-radius: 4px;
}
div.progressWithTextDown
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"]
  div[class="ant-progress-bg"] {
  border-radius: 4px;
}

div.progressWithUpTextBox span[class="ant-progress-text"] {
  display: block;
  width: fit-content;
  float: right;
  color: #344054;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid var(--gray-200, #eaecf0);
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03),
    0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  padding: 8px;
}
div.progressWithUpTextBox
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"] {
  border-radius: 4px;
}

div.progressWithUpTextBox div[class="ant-progress-outer"] {
  padding-inline-end: 0px !important;
  border-radius: 4px;
}
div.progressWithUpTextBox
  div[class="ant-progress-outer"]
  div[class="ant-progress-inner"]
  div[class="ant-progress-bg"] {
  border-radius: 4px;
}

div.xxscircleProgress div[class="ant-progress-inner"] {
  width: 58px !important;
  height: 58px !important;
}
div.xxscircleProgress div[class="ant-progress-inner"] svg circle {
  /* stroke-width: 6px !important; */
}
div.xxscircleProgress
  div[class="ant-progress-inner"]
  span[class="ant-progress-text"] {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

div.xscircleProgress div[class="ant-progress-inner"] {
  width: 160px !important;
  height: 160px !important;
}
div.xscircleProgress div[class="ant-progress-inner"] svg circle {
  /* stroke-width: 16px !important; */
}
div.xscircleProgress
  div[class="ant-progress-inner"]
  span[class="ant-progress-text"] {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

div.smcircleProgress div[class="ant-progress-inner"] {
  width: 200px !important;
  height: 200px !important;
}
div.smcircleProgress div[class="ant-progress-inner"] svg circle {
  /* stroke-width: 20px !important; */
}
div.smcircleProgress
  div[class="ant-progress-inner"]
  span[class="ant-progress-text"] {
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
}
div.mdcircleProgress div[class="ant-progress-inner"] {
  width: 240px !important;
  height: 240px !important;
}
div.mdcircleProgress div[class="ant-progress-inner"] svg circle {
  /* stroke-width: 24px !important; */
}
div.mdcircleProgress
  div[class="ant-progress-inner"]
  span[class="ant-progress-text"] {
  font-size: 36px;
  font-weight: 500;
  line-height: 44px;
}
div.lgcircleProgress div[class="ant-progress-inner"] {
  width: 280px !important;
  height: 280px !important;
}
div.lgcircleProgress div[class="ant-progress-inner"] svg circle {
  /* stroke-width: 24px !important; */
}
div.lgcircleProgress
  div[class="ant-progress-inner"]
  span[class="ant-progress-text"] {
  font-size: 48px;
  font-weight: 500;
  line-height: 60px;
}
