.activeIcon:hover {
  font-size: 18px;
}

.activeIcon {
  transition: all 0.2s ease;
  font-size: 16px;
  color: #667085;
  margin-left: 10px;
  cursor: pointer;
}

.activeIconTyut:hover {
  font-size: 18px;
}

.activeIconTyut {
  transition: all 0.2s ease;
  font-size: 16px;
  color: #667085;
  cursor: pointer;
}

.thumbnailDiv div {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}
