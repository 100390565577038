.App {
  text-align: center;
}
body {
  /* background-color: #efefef; */
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.ant-form-item {
  margin-bottom: 16px;
}
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 2px;
}

input[disabled] {
  color: #2f3542 !important;
}
input[disabled][type] {
  color: #2f3542 !important;
}
textarea[disabled] {
  color: #293241 !important;
}
.ant-select-disabled div[class="ant-select-selector"] {
  color: #2f3542 !important; /* Your desired color */
  background-color: #fff !important;
}
.ant-picker-disabled {
  color: #2f3542 !important;
  background-color: #fff !important;
}
.ant-input-outlined[disabled],
.ant-input-number-input[disabled] {
  color: #2f3542 !important;
  background-color: #fff !important;
  width: 100%;
}
.ant-btn[disabled] {
  color: #2f3542 !important;
  background-color: #fff;
  border-color: #7369f4;
}
.rowClassName {
  cursor: pointer;
}
.text-center {
  text-align: center;
}
.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding-block: 6px;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 16px; */
  /* padding-block: 6px; */
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding-block: 6px;
}
.grey-text {
  color: #475467;
}

.hyperlink {
  color: #0a00ff !important;
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px !important;
  cursor: pointer;
}
.hyperlink:hover {
  text-decoration: underline;
}
.tableHeader {
  color: #475467;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-block: 12px;
}
.tableContainer {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 0px !important;
}
.fileListItem {
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  display: flex;
  padding: 3px 4px 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin-top: 6px;
  cursor: pointer;
}
.dummyItem {
  margin: 0px;
  /* margin-bottom: 24px; */
}
.dummyItem div div div[class="ant-form-item-control-input"] {
  min-height: 0px;
}

.rd3t-link {
  stroke: #3d5a80 !important;
}
.whiteText {
  color: #fff;
}
.ant-card-head-title {
  font-weight: 500;
}
.zsiq-newtheme.zls-sptwndw {
  right: 15px !important;
  bottom: 80px !important;
}
.zsiq-newtheme.zls-sptwndw {
  left: 5px !important;
}
.siq_bR {
  bottom: 80px !important;
  left: 20px !important;
}

.zsiq_theme1 div.zsiq_cnt {
  bottom: 200px !important;
  left: 70px !important;
}
.zsiq_theme1 .zsiq_cnt:after {
  left: -205px !important;
}
.siq-hide-bubble,
.zsiq_theme1 .zsiq_cnt .siqico-close:hover {
  right: -10px !important;
}

.ant-table-bordered {
  box-shadow: 0 0.125rem 0 rgba(10, 10, 10, 0.04);
}

.ant-table-wrapper table {
  border-radius: 8px;
}

.ant-menu .ant-menu-title-content {
  transition: none;
}
.ant-btn-link {
  color: #0a00ff !important;
}

.sopQuestion:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
  background-color: #f0f1f6; /* Almost white shade with a hint of the primary color */
  overflow: hidden;
  color: #2f3542; /* Ensure text color remains consistent */
}

.ant-select-disabled .ant-select-selection-item {
  color: #2f3542 !important;
}

.filter-tree .ant-select-selection-item {
  background: #fff !important;
}
.filter-tree .ant-select-selection-item-remove {
  display: none !important;
}
.filter-tree-dropdown .ant-select-tree .ant-select-tree-switcher {
  margin-left: -15px;
}
.filter-tree-select .ant-select-selector {
  border: 1px solid #7369f4 !important;
}

.custom-select .ant-select-clear {
  font-size: 15px;
}
.custom-select .ant-select-selector {
  padding-left: 32px !important;
}

.ant-select .ant-select-selection-placeholder {
  color: #667085;
  opacity: 0.7;
}
.ant-input::placeholder {
  color: #667085;
  opacity: 0.7;
}
.ant-input-number .ant-input-number-input-wrap input::placeholder {
  color: #667085;
  opacity: 0.7;
}
.ant-picker .ant-picker-input input::placeholder {
  color: #667085;
  opacity: 0.7;
}
.custom-mentions-input-pl input::placeholder,
.custom-mentions-input-pl textarea::placeholder {
  color: #667085;
  opacity: 0.7;
  font-family: "Inter";
}

.custom-input-search .ant-input-wrapper .ant-input-affix-wrapper {
  border-radius: 6px;
}
.custom-input-search .ant-input-wrapper .ant-input-group-addon {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 992px) {
  .hiddenBelowTab {
    display: none !important;
  }
}
