button.basic {
  border-radius: 12px;
  height: 24px;
}
button.basic span {
  border-radius: 12px !important;
}
button.basic div[class="ant-switch-handle"] {
  width: 20px;
  height: 20px;
}
