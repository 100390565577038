body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}

::-webkit-scrollbar-track {
  background: #ececec;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c9c9c9; 
  border-radius: 4px;
}


::-webkit-scrollbar-thumb:hover {
  background: #d6d6d6;
}
